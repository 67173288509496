import Dropzone from "dropzone";
import {t} from '../i18n';
import $ from "jquery";

function initDropzoneForms() {
    if ($("#multi_upload").length) {
        initDropzone("#multi_upload", "image/jpeg,image/jpg,image/png,.mp4,.mov,.avi,.mkv,.wmv,.flv,.m4v", "/files_groups/upload_multiple", true, 500,500);
    };

    function initDropzone(id,acceptedFiles, url, uploadMultiple, maxFiles, maxSize) {

        var myDropzone = new Dropzone(id, {
            acceptedFiles,
            url,
            uploadMultiple,
            maxFiles,
            maxFilesize: maxSize,
            parallelUploads: 800,
            dictRemoveFile: "",
            dictResponseError: "Refresh the page. Internet ERORR",
            dictFileTooBig: "File is too big ({{filesize}}MB). Max filesize: {{maxFilesize}}MB.",
            headers: {
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
            },
            sending: function (file, xhr, formData) {
                formData.append("empty_group", this.element.getAttribute("data-empty-group"))
                formData.append("album_id", this.element.getAttribute("data-album"))
            },
            success: function (file, response) {
                file.previewElement.innerHTML = "";
                const btn_name = (text) => `<button class='btn cta'><span class='btn--text'>${text}</span></button>`;
                const yes_status = (text) => `<div class="status success">${text}</div>`;
                const no_status = (text) => `<div class="status danger">${text}</div>`;
                this.removeFile(file);
                document.getElementById("continue-btn").style.display = "block";
                document.getElementById("clear-files").style.display = "block";

                if (response.status === 'ok') {
                    $('#attachmentPartial').html(response.attachmentPartial);
                    $('#photos_count').html(response.photos_count);
                    $('#modal-photo-count').html(response.photos_count);
                    if (response.disable_btn === true) {
                        $('#empty_pairs').hide();
                        $('#empty_pairs_status').html(no_status(t('no')));
                    } else {
                        $('#empty_pairs').show();
                        $('#empty_pairs_status').html(yes_status(t('yes')));
                    }

                    const type = file.type.startsWith("video") ? "video" : "photo"
                    const file_group = response.files_groups.findLast((group) => {
                        return group.original_photoname === file.name || group.original_videoname === file.name
                    })
                }
            }
        });
    }
}

document.addEventListener("turbolinks:load", function () {
    Dropzone.autoDiscover = false;
    initDropzoneForms();
});
