const translations = {
  en: {
    'continue': 'Continue',
    'save_changes': 'Save Changes',
    'yes': 'Yes',
    'no': 'No',
    'field_req': 'Field is required',
    'must_start_with_cap': 'Must start with a capital letter',
    'please_attach_a_file': 'Please attach a file',
    'please_fill_a_date': 'Please fill a date',
    'must_be_accepted': 'Must be accepted',
    'invalid_url': 'Invalid URL',
    'only_numbers': 'Only numbers! ',
    'must_be_a_least': 'Must be at least ',
    'symbols': ' symbols',
    'should_be_no_more': ' Should be no more'
  },
  ru: {
    'continue': 'Далее',
    'save_changes': 'Сохранить изменения',
    'yes': 'Да',
    'no': 'Нет',
    'field_req': 'Поле обязательно',
    'must_start_with_cap': 'Должно начинаться с большой буквы',
    'please_attach_a_file': 'Пожалуйста, прикрепите файл',
    'please_fill_a_date': 'Пожалуйста, введите дату',
    'must_be_accepted': 'Должен быть принят',
    'invalid_url': 'Неверный URL',
    'only_numbers': 'Только цифры! ',
    'must_be_a_least': 'Должно быть не менее ',
    'symbols': ' символов',
    'should_be_no_more': ' Должно быть не более '
  }
}

export function t(key) {
  const locale = document.getElementsByTagName('body')[0].dataset['locale'];
  return translations[locale][key];
}
