var jQuery = require('jquery')

global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require('../js/app')

import "controllers"

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

import "../stylesheets/styles.scss";
