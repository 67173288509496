import { Controller } from "stimulus"
import $ from "jquery";
// import consumer from "channels/consumer"
// import CableReady from 'cable_ready'

export default class extends Controller {
  
  static targets = [ ]
  
  initialize() {
    $('[data-js-search]').keyup(function(event) {
      const search_term = $(this).val();
      
      $.ajax({
        url: `/albums?name=${search_term}`,
        type: 'GET',
        dataType: 'json',
        success: function (response){
          $('#search-albums').html(response);
        },
        error: function (response){
        }
      })
    });
  }
}
