import { Controller } from "stimulus"
import $ from 'jquery';
import Dropzone from "dropzone";
import {t} from '../js/i18n';

export default class extends Controller {
  
  initialize() {
    $('[data-form="hidden"]').each((idx, form) => {
      $(form)
        .find('[name="toggler"]')
        .on("change", (e) => {
          if ($(e.currentTarget).is(":checked")) {
            $(form).find(".description").addClass("show");
          } else {
            $(form).find(".description").removeClass("show");
            $(form).find('input.form-field__input').val('');
          }
        });
    });
  
    $(".item").each((idx, el) => {
      $(el)
        .find(".del")
        .on("click", function (e) {
          const value = $('#album_delete_groups').val();
          const response_id = $(this).data('id');
          $.ajax({ url: 'rm_files_group',
            data: {files_group: response_id},
            type: 'POST',
            success: function (response){
              const btn_name = (text) => `<button class='btn cta'><span class='btn--text'>${text}</span></button>`;
              const yes_status = (text) => `<div class="status success">${text}</div>`;
              const no_status = (text) => `<div class="status danger">${text}</div>`;
              document.getElementById("continue-btn").style.display = "block";
              $('#attachmentPartial').html(response.attachmentPartial);
              $('#photos_count').html(response.photos_count);
              if (response.disable_btn === true) {
                $('#empty_pairs').hide();
                $('#empty_pairs_status').html(no_status(t('no')));
              } else {
                $('#empty_pairs').show();
                $('#empty_pairs_status').html(yes_status(t('yes')));
              }
            }
          });
          
          if (value) {
            const ids = value.split(',');
            ids.push(response_id)
            $('#album_delete_groups').val(ids.join());
          } else {
            $('#album_delete_groups').val(response_id);
          }
          $(el).hide();
        });
    });
  
    $(".item").each((idx, el) => {
      $(el)
        .find(".del-mask")
        .on("click", function (e) {
          const value = $('#album_delete_masks').val();
          const response_id = $(this).data('id');
          const temp_response_id = $(this).data('temp-id');
          $.ajax({ url: 'remove_mask',
            data: {files_group: response_id},
            type: 'POST'
          });
          if (value) {
            const ids = value.split(',');
            ids.push(temp_response_id)
            $('#album_delete_masks').val(ids.join());
          } else {
            $('#album_delete_masks').val(temp_response_id);
          }
          // $(el).hide();
        });
    });
  
    /*
        Dropzone.prototype.defaultOptions.dictDefaultMessage = "Drop files here to upload";
        Dropzone.prototype.defaultOptions.dictFallbackMessage = "Your browser does not support drag'n'drop file uploads.";
        Dropzone.prototype.defaultOptions.dictFallbackText = "Please use the fallback form below to upload your files like in the olden days.";
        Dropzone.prototype.defaultOptions.dictFileTooBig = "File is too big ({{filesize}}MiB). Max filesize: {{maxFilesize}}MiB.";
        Dropzone.prototype.defaultOptions.dictInvalidFileType = "You can't upload files of this type.";
        Dropzone.prototype.defaultOptions.dictResponseError = "Server nu raspunde {{statusCode}} code.";
        Dropzone.prototype.defaultOptions.dictCancelUpload = "Cancel upload";
        Dropzone.prototype.defaultOptions.dictCancelUploadConfirmation = "Are you sure you want to cancel this upload?";
        Dropzone.prototype.defaultOptions.dictRemoveFile = "Remove file";
        Dropzone.prototype.defaultOptions.dictMaxFilesExceeded = "You can not upload any more files.";
    */
    
    Array.prototype.slice.call(document.querySelectorAll('.photo_upload')).forEach(element => {
      if (element.dropzone) {
        element.dropzone.destroy();
      } // This is only necessary if you are going to use ajax to reload dropzones. Without this, you will get a "Dropzone already exists" error.
      const files_group_id = element.getAttribute("data-value");
      var myDropzone = new Dropzone(element, {
        acceptedFiles: "image/jpeg,image/jpg,image/png",
        uploadMultiple: false,
        maxFilesize: 100,
        maxFiles: 1,
        url: `/files_groups/${files_group_id}/upload_photo`,
        dictRemoveFile: "",
        dictResponseError: "Refresh the page. Internet ERORR",
        dictFileTooBig: "File is too big ({{filesize}}MB). Max filesize: {{maxFilesize}}MB.",
        headers: {
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        },
        sending: function (file, xhr, formData) {
          formData.append("group_id", files_group_id)
          formData.append("album_id", element.getAttribute("data-album"))
        },
        success: function (file, response) {
          const btn_name = (text) => `<button class='btn cta'><span class='btn--text'>${text}</span></button>`;
          const yes_status = (text) => `<div class="status success">${text}</div>`;
          const no_status = (text) => `<div class="status danger">${text}</div>`;
          document.getElementById("continue-btn").style.display = "block";
          document.getElementById("clear-files").style.display = "block";
          this.removeFile(file);
        
          if (response.status === 'ok') {
            const value = $('#albumFiles').val();
            // value has some string like '2' or '2,3'
            if (value) {
              const ids = value.split(',')
              ids.push(response.files_group_id)
              $('#albumFiles').val(ids.join());
            } else {
              $('#albumFiles').val(response.files_group_id);
            }
  
            $('#attachmentPartial').html(response.attachmentPartial);
            $('#photos_count').html(response.photos_count);
            $('#modal-photo-count').html(response.photos_count);
            if (response.disable_btn === true) {
              $('#empty_pairs').hide();
              $('#empty_pairs_status').html(no_status(t('no')));
            } else {
              $('#empty_pairs').show();
              $('#empty_pairs_status').html(yes_status(t('yes')));
            }

          } else if (response.status === 'error') {
            $('#errorsPartial').html(response.errorsPartial);
            $('#error_batch').modal('show');
          }
        }
      });
    });
  
    // Video Upload
    Array.prototype.slice.call(document.querySelectorAll('.video_upload')).forEach(element => {
      if (element.dropzone) {
        element.dropzone.destroy();
      } // This is only necessary if you are going to use ajax to reload dropzones. Without this, you will get a "Dropzone already exists" error.
      const files_group_id = element.getAttribute("data-value")
      var myDropzone = new Dropzone(element, {
        acceptedFiles: ".mp4,.mov,.avi,.mkv,.wmv,.flv,.m4v",
        uploadMultiple: false,
        maxFilesize: 500,
        maxFiles: 1,
        url: `/files_groups/${files_group_id}/upload_video`,
        dictRemoveFile: "",
        dictResponseError: "Refresh the page. Internet ERORR",
        dictFileTooBig: "File is too big ({{filesize}}MB). Max filesize: {{maxFilesize}}MB.",
        headers: {
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        },
        sending: function (file, xhr, formData) {
          formData.append("group_id", files_group_id)
          formData.append("album_id", element.getAttribute("data-album"))
        },
        success: function (file, response) {
          const btn_name = (text) => `<button class='btn cta'><span class='btn--text'>${text}</span></button>`;
          const yes_status = (text) => `<div class="status success">${text}</div>`;
          const no_status = (text) => `<div class="status danger">${text}</div>`;
          
          document.getElementById("continue-btn").style.display = "block";
          document.getElementById("clear-files").style.display = "block";
          this.removeFile(file);
        
          if (response.status === 'ok') {
            const value = $('#albumFiles').val();
            // value has some string like '2' or '2,3'
            if (value) {
              const ids = value.split(',')
              ids.push(response.files_group_id)
              $('#albumFiles').val(ids.join());
            } else {
              $('#albumFiles').val(response.files_group_id);
            }
  
            $('#attachmentPartial').html(response.attachmentPartial);
            $('#photos_count').html(response.photos_count);
            $('#modal-photo-count').html(response.photos_count);
          
            if (response.disable_btn === true) {
              $('#empty_pairs').hide();
              $('#empty_pairs_status').html(no_status(t('no')));
            } else {
              $('#empty_pairs').show();
              $('#empty_pairs_status').html(yes_status(t('yes')));
            }

          } else if (response.status === 'error') {
            $('#errorsPartial').html(response.errorsPartial);
            $('#error_batch').modal('show');
          }
        }
      });
    });
    
    // Mask Upload
    Array.prototype.slice.call(document.querySelectorAll('.mask_upload')).forEach(element => {
      if (element.dropzone) {
        element.dropzone.destroy();
      }
      const files_group_id = element.getAttribute("data-value")
      var myDropzone = new Dropzone(element, {
        createImageThumbnails: false,
        acceptedFiles: 'image/jpeg,image/jpg,image/png',
        uploadMultiple: false,
        maxFilesize: 100,
        maxFiles: 1,
        url: `/files_groups/${files_group_id}/upload_mask`,
        dictRemoveFile: "",
        dictResponseError: "Refresh the page. Internet ERORR",
        dictFileTooBig: "File is too big ({{filesize}}MB). Max filesize: {{maxFilesize}}MB.",
        headers: {
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        },
        sending: function (file, xhr, formData) {
          $(element).hide();
          $(".dz-preview").hide();
          formData.append("group_id", files_group_id)
          formData.append("album_id", element.getAttribute("data-album"))
        },
        success: function (file, response) {
          $(".dz-preview").hide();
          this.removeFile(file);
          if (response.status === 'ok') {

           $("#file-group-mask-" + response.file_group_id).html(response.file_group_partial);
          
          } else if (response.status === 'error') {
            $('#error_batch').modal('show');
          }
        }
      });
    });
  }
  connect() {
  }
}
