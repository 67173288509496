import transition from "./plugins/transition.js";
import dropdown from "./plugins/dropdown.js";
import modal from "./plugins/modal.js";
import carousel from "./plugins/carousel";
import {t} from './i18n'
import './validate'
import sprite from './../images/sprite.svg';
import '../js/lib/dropzone_forms';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import 'js/switch_plan';

document.addEventListener('turbolinks:load', function() {
  //Phone mask
  const form = document.querySelector('.auth--form');
  if (form) {
    const input = form.querySelector('#phone_number');
    if (input) {
      const iti = intlTelInput(input, {
        // options for intl-tel-input, e.g.:
        separateDialCode: true,
        callback: function() {
          const countryCode = iti.getSelectedCountryData().dialCode;
          const phoneNumber = input.value;
          input.value = `+${countryCode} ${phoneNumber}`;
        },
        utilsScript:
          'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js',
      });
      form.addEventListener('submit', function () {
        const phoneNumber = iti.getNumber();
        input.value = phoneNumber;
      });
    }
  }
  // GET SPRITE
  const xhttp = new XMLHttpRequest();
  xhttp.onreadystatechange = function () {
    if (this.readyState == 4 && this.status == 200) {
      const div = document.createElement('div');
      div.hidden = true;
      div.innerHTML = this.response;
      document.body.insertBefore(div, document.body.childNodes[0]);
    }
  };
  xhttp.open('GET', sprite, true);
  xhttp.send();

  // NAVBAR TOGGLE
  $(".navbar__toggle").click(function (e) {
    $(e.currentTarget).toggleClass('active');
    $(".navbar__content").toggleClass('show');
  });
  
  $(".copy-link").each(function(){
    var text = this.getAttribute('data-value');
    /* Or for the event */
    $(this).click(function() {
      navigator.clipboard.writeText(text).then(function() {
      }, function(err) {
      });
    });
  });
  
  $('.form__input input, .form__input textarea').focus(function () {
    $(this).closest('.form__input').addClass('focused');
  });
  
  $('.form__input input, .form__input textarea').blur(function () {
    if ($(this).val().length === 0) {
      $(this).closest('.form__input').removeClass('focused');
    }
  });
  
  $('.form__input input, .form__input textarea').each(function () {
    if ($(this).val().length === 0) {
      $(this).closest('.form__input').removeClass('focused');
    } else {
      $(this).closest('.form__input').addClass('focused');
    }
  });
  
  (function () {
    const dateInput = document.querySelector('[data-inputmask]');
    let dateInputMask = null;
    
    if (dateInput) {
      dateInputMask = new IMask(dateInput, {
        mask: Date,
        autofix: true,
        lazy: false,
        overwrite: true,
      });
    }
    
    const formKyc = $('.form__kyc');
    
    formKyc.find('select').change(function() {
      const groupDiv = $(this).closest('.form__select');
      
      if ($(this).val() === '') {
        groupDiv.append(`<small class="text--danger">${t('field_req')}</small>`);
      } else {
        groupDiv.find('.text--danger').remove();
      }
    });
    
    formKyc.find('input[type=checkbox][data-validate=required]').change(function () {
      const groupDiv = $(this).closest('.form__checkbox');
      
      if (this.checked) {
        groupDiv.find('.text--danger').remove();
      } else {
        groupDiv.append(`<small class="text--danger">${t('must_be_accepted')}</small>`);
      }
    });
    
    formKyc.submit(event => {
      let hasErrors = false;
      
      formKyc.find('select').each(function () {
        const groupDiv = $(this).closest('.form__select');
        groupDiv.find('.text--danger').remove();
        
        if ($(this).val() === '') {
          hasErrors = true;
          groupDiv.append(`<small class="text--danger">${t('field_req')}</small>`);
        }
      });
      
      formKyc.find('input[type=text]').each(function () {
        const groupDiv = $(this).closest('.form__input');
        groupDiv.find('.text--danger').remove();
        
        if ($(this).val() === '') {
          hasErrors = true;
          groupDiv.append(`<small class="text--danger">${t('field_req')}</small>`);
        } else if ($(this).attr('name') === 'profile[birthday]') {
          if (dateInputMask.value.indexOf('_') >= 0) {
            hasErrors = true;
            groupDiv.append(`<small class="text--danger">${t('please_fill_a_date')}</small>`);
          }
        }
      });
      
      if (hasErrors) {
        event.preventDefault();
        formKyc.find('input[type=submit], button[type=submit]').addClass('animated shake');
        
        setTimeout(function () {
          formKyc.find('input[type=submit], button[type=submit]').removeClass('animated shake');
        }, 3000);
      }
    });
  })();
  
  // AUTH CAROUSEL
  (function () {
    $(".auth--carousel .owl-carousel").owlCarousel({
      autoplay: true,
      autoplayTimeout: 7000,
      loop: true,
      margin: 100,
      dots: true,
      items: 1,
      onInitialized: function (e) {
        $(e.target).find(".owl-dot").html('<svg><use xlink:href="' + sprite + '?#dot"></use></svg>');
      },
    });
  })();

  // BALANCE REFILL
  (function () {
    function setActiveRow() {
      $('[name="refill"]').each((index, item) => {
        if ($(item).is(":checked")) {
          $(item).closest("tr").addClass("active");
          $(".table--balance .value").text($(item).val());
        } else {
          $(item).closest("tr").removeClass("active");
        }
      });
    }

    setActiveRow();
    $('[name="refill"]').on("change", setActiveRow);
  })();

  // PASSWORD INPUT
  (function () {
    let isVisible = false;
    let symbol = "*";
    const icons = ["#eye_c", "#eye_o"];

    function toggleVisible(e) {
      const parentEl = $(this).parent(".form--item__pass");
      const inpEl = parentEl.find("input");
      const iconEl = parentEl.find("use");

      if (parentEl.hasClass("unsecured")) {
        parentEl.removeClass("unsecured");
        inpEl.attr("type", "password");
        iconEl.attr("xlink:href", `${sprite}?${icons[0]}`);
      } else {
        parentEl.addClass("unsecured");
        inpEl.attr("type", "text");
        iconEl.attr("xlink:href", `${sprite}?${icons[1]}`);
      }
    }

    $(".form--item__pass .icon").on("click", toggleVisible);
  })();

  
  $(".form--item.copy").each((idx, el) => {
    $(el)
      .find(".icon")
      .on("click", function (e) {
        $(e.currentTarget).addClass("done");
        
        $(el).find("input")[0].select();
        document.execCommand("copy");
      });
  });
  
  // progress bar
  (function () {
    $("#percent").on("change", function () {
      var val = parseInt($(this).val());
      var $circle = $("#svg #bar");
      
      if (isNaN(val)) {
        val = 100;
      } else {
        var r = $circle.attr("r");
        var c = Math.PI * (r * 2);

        if (val < 0) {
          val = 0;
        }
        if (val > 100) {
          val = 100;
        }
        
        var pct = ((100 - val) / 100) * c;
        
        $circle.css({ strokeDashoffset: pct });
        
        $("#cont").attr("data-pct", val);
      }
    });
  })();
  (function () {
    $("#percent1").on("change", function () {
      var val = parseInt($(this).val());
      
      var $circle = $("#svg1 #bar1");
      
      if (isNaN(val)) {
        val = 100;
      } else {
        var r = $circle.attr("r");
        var c = Math.PI * (r * 2);
        
        if (val < 0) {
          val = 0;
        }
        if (val > 100) {
          val = 100;
        }
        
        var pct = ((100 - val) / 100) * c;
        
        $circle.css({ strokeDashoffset: pct });
        
        $("#cont1").attr("data-pct", val);
      }
    });
  })();
  
  // inputmask
  (function () {
    const inputmask = $(".inputmask input");
    
    inputmask.each((index, item) => {
      if ($(item).val().length) {
        $(item).closest(".inputmask").addClass("filled");
        $(item).closest(".inputmask").find(".text").html("Mask Added");
        $(item).prop("disabled", true);
      } else {
        $(item).closest(".inputmask").removeClass("filled");
        $(item).closest(".inputmask").find(".text").html("+ Add Mask");
        $(item).prop("disabled", false);
      }
      
      $(item)
        .closest(".inputmask")
        .find(".inputmask__delete")
        .click(function (e) {
          $(item).val("").change();
        });
    });
    
    $(".inputmask input").on("change", function () {
      if ($(this).val().length) {
        $(this).closest(".inputmask").addClass("filled");
        $(this).closest(".inputmask").find(".text").html("Mask Added");
        $(this).prop("disabled", true);
      } else {
        $(this).closest(".inputmask").removeClass("filled");
        $(this).closest(".inputmask").find(".text").html("+ Add Mask");
        $(this).prop("disabled", false);
      }
    });
  })();
  
  // TUTORIAL
  (function () {
    const owl = $(".panel--tutorial .owl-carousel").owlCarousel({
      responsiveRefreshRate: 50,
      autoplay: false,
      mouseDrag: false,
      touchDrag: false,
      items: 1,
      animateOut: "fadeOut",
      animateIn: "fadeIn",
      //autoHeight: true,
      nav: true,
      //navText: ["Prev", "Next"],
      responsive: {
        0: {
          autoHeight: true,
        },
        1000: {
          autoHeight: false,
        }
      }
    });

    $('[data-close="tutorial"]').click((e) => {
      $(".panel--tutorial").hide(250);
      $("#tutorial").modal("toggle");
    });
    $('[data-action="next"]').click((e) => {
      owl.trigger("next.owl.carousel");
    });
  })();
  

  
  // Submit the form when the modal button is clicked
  $('#submit-modal-form').on('click', function() {
    $('.album-form').submit();
  });


  // Do not show again logic
  function updateButton() {
    if (localStorage.getItem('do_not_show_confirmation') === 'true') {
      $("button.btn.cta.new#btnClick").attr("type", "submit");
      $("button.btn.cta.new#btnClick").removeAttr("data-toggle");
      $("button.btn.cta.new#btnClick").removeAttr("href");


    } else {
      $("button.btn.cta.new#btnClick").attr("type", "button");
      $("button.btn.cta.new#btnClick").attr("data-toggle","modal");
      $("button.btn.cta.new#btnClick").attr("href","#start");
    }
  }


  $('.del-file-group-modal').click(function(e){
    e.preventDefault();

    $('.remove-group-btn-modal').attr('data-id', $(this).data("id"))
                                .attr('data-file-group-id', $(this).data("file-group-id"))
                                .attr('data-type', $(this).data("type"));
  });

  let removeFileGroupModal = localStorage.getItem('removeFileGroupModal') === 'false' ? false : true;

  function toggleRemoveFileGroupModal() {
    removeFileGroupModal = !removeFileGroupModal;
    localStorage.setItem('removeFileGroupModal', removeFileGroupModal);
    if (!removeFileGroupModal) {
      $('.del-file-group-modal').hide();
      $('.del-file-group').show();
    }
  }
    
  if (!removeFileGroupModal) { 
    $('.del-file-group-modal').hide();
    $('.del-file-group').show();
  }

  let targetAlbum = document.getElementById('attachmentPartial');
  if (targetAlbum) {
    let config = { attributes: true, childList: true, subtree: true };
    let observer = new MutationObserver(callback);
    observer.observe(targetAlbum, config);
  } else {
    console.error('Failed to find #attachmentPartial element to observe mutations.');
  }

function callback(mutationsList, observer) {
    mutationsList.forEach(function(mutation) {
        if (mutation.type === 'childList') {
          if (!removeFileGroupModal) { 
              $('.del-file-group-modal').hide();
              $('.del-file-group').show();
            }
        }
    });
}
  $('#can_create_empty_albums').on('change', function(e) {
    $.ajax({
      url: '/update',
      method: 'PUT',
      data: $('form#toggle-form').serialize(),
      success: function(response) {
      },
      error: function(error) {
      }
    });
  });

  updateButton();

  $('#do_not_show_confirmation').on('change', function() {
    // Store the state of the checkbox in localStorage
    localStorage.setItem('do_not_show_confirmation', $(this).prop('checked'));
    toggleRemoveFileGroupModal();
    updateButton();
  });

});
