import isEmail from 'validator/lib/isEmail';
import isInt from 'validator/lib/isInt';
import isURL from 'validator/lib/isURL';
import isUppercase from 'validator/lib/isUppercase';
import isMobilePhone from 'validator/lib/isMobilePhone';
import toDate from 'validator/lib/toDate';

import {t} from './i18n';

const isEmpty = value => value === undefined || value === null || value === '';

export const email = value => !isEmail(value) &&
    'Invalid E-mail address';

export const isUrlValid = value => !isEmpty(value) && !isURL(value) &&
  t('invalid_url');

export const requiredByField = nameField => value => isEmpty(value) &&
  `${nameField} is required field` ;

export const isCapitalize = value => !isEmpty(value) && !isUppercase(value.charAt(0)) &&
  t('must_start_with_cap');

export const isCyrillic = string => {
  const cyrillicPattern = /^[\u0400-\u04FF]+$/;
  const stringWithoutSpaces = string.replace(/-/g, '').replace(/\s+/g, '');

  return !cyrillicPattern.test(stringWithoutSpaces) && 'Только кириллические символы'
};

export const mobile = value => !isEmpty(value) && !isMobilePhone(value) && 'Invalid mobile phone number';

export const mobileEmpty = value => !isMobilePhone(value) && 'Invalid mobile phone number';

export const url = value => !isEmpty(value) && !isURL(value) &&
  'Недопустимый URL';

export const required = value => isEmpty(value) &&
  t('field_req');

export const requiredPhoneNumber = length => value => !isEmpty(value) && !isInt(value) && value.length !== length &&
  `Длина поля должна составлять ${length} символов и только цифры`;

export const requiredLength = length => value => value.length !== length &&
  `Длина поля должна составлять ${length} символ(ов) `;

export const minLength = min => value => !isEmpty(value) && value.length < min &&
  t('must_be_a_least') + ` ${min} ` + t('symbols');

export const maxLength = max => value => !isEmpty(value) && value.length > max &&
  t('should_be_no_more') + ` ${max} ` + t('symbols');

export const integer = value => !isEmpty(value) && !isInt(value) &&
  t('only_numbers');

export const isBigger = sum => value => value > sum &&
  `Не больше ${sum}`;

export const isLower = sum => value => value < sum &&
  `Не меньше ${sum}`;

export const isNumericLengthWithoutSymbols = length => value => !isEmpty(value) &&  value.replace(/-/g, '').replace(/\s+/g, '').length < length &&
  `Не менее ${length} цифр`;

export const isAdvancedUpload = () => {
  const div = document.createElement( 'div' );
  return ( ( 'draggable' in div ) || ( 'ondragstart' in div && 'ondrop' in div ) ) && 'FormData' in window && 'FileReader' in window;
};

export const hideError = (el) => {
  const error = el.nextElementSibling;
  
  if( error && error.classList.contains('text--danger')) {
    error.parentNode.removeChild(error);
    if(el.classList.contains('form-control_with-error')) {
      el.classList.remove('form-control_with-error', 'form-control_error');
    }
  }
};

export const showError = (el, text) => {
  const error = `<small class="text--danger">${text}</small>`;
  
  el.insertAdjacentHTML('afterend', error);
  el.nextElementSibling.classList.add('error-text_with-margin');
  el.classList.add('form-control_with-error', 'form-control_error');
};

export const removeErrorInNewFields = el => {
  hideError(el);
  const parent = el.closest('.input_validate');
  const currentErrors = parent.querySelectorAll('.text--danger');
  const currentErrors2 = parent.querySelectorAll('.field_with_errors');
  const formButtons = parent.closest('.album-form').querySelectorAll('#btnClick');
  //const label = parent.querySelector('.form-field__label');
  const input = parent.querySelector('.form-field__input');

  parent.classList.remove('danger');
  //label.classList.remove('form-field__label_error');
  if (typeof(input) != 'undefined' && input != null)
  {
    input.classList.remove('form-field__input_with-error');
    input.classList.remove('margin-5');
  }

  if (formButtons.length > 0) {
    formButtons.forEach(btn => btn.disabled = false)
  };

  currentErrors.forEach(error => parent.removeChild(error));

  if (currentErrors2.length > 0) {
    currentErrors2.forEach(el => el.classList.remove('field_with_errors'));
  };
};

export const showErrorInNewFields = (el, errors) => {
  const addHtmlError = (text) => `<small class="text--danger">${text}</small>`;
  const parent = el.closest('.input_validate');
  const formButtons = parent.closest('.album-form').querySelectorAll('#btnClick');
  // const label = parent.querySelector('.form-field__label');
  const input = parent.querySelector('.form-field__input');

  parent.classList.add('danger');
  // label.classList.add('form-field__label_error');
  input.classList.add('form-field__input_with-error');
  const existingErrors = parent.querySelectorAll('small.error-text');

  existingErrors.forEach(el => el.remove());

  if (formButtons.length > 0) {
    formButtons.forEach(btn => btn.disabled = true)
  };

  errors.map( error => parent.insertAdjacentHTML('beforeend', addHtmlError(error)) );
};
