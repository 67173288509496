import {
  email, integer, requiredByField,
  showErrorInNewFields, isCapitalize, isBigger, isUrlValid,
  required, minLength, maxLength,
  removeErrorInNewFields, mobile, isLower, requiredPhoneNumber
} from '../validator';

const handleErrors = (value, validators) => {
  let errors = [];

  validators.forEach(validator => {
    if (validator(value)) {
      errors.push(validator(value));
    }
  });

  return errors;
};

export const initValidators = () => {
  const userName = document.querySelectorAll('input[data-validate="user_name"]');
  const emailInput = document.querySelectorAll('input[data-validate="email"]');
  const phone = document.querySelectorAll('input[data-validate="phone"]');
  const name = document.querySelectorAll('input[data-validate="name"]');
  const webUrl = document.querySelectorAll('input[data-validate="web_url"]');
  const fileGroupText = document.querySelectorAll('input[data-validate="file_group_text"]');
  
  const requiredFields = document.querySelectorAll('input[data-validate="required"]');
  const requiredSelects = document.querySelectorAll('select[data-validate="required"]');
  
  const inputs = [];
  
  if(webUrl.length > 0) {
    inputs.push({
      inputsFields: webUrl,
      validators: [ isUrlValid ]
    });
  }
  
  if(emailInput.length > 0) {
    inputs.push({
      inputsFields: emailInput,
      validators: [ email ]
    });
  }

  if(name.length > 0) {
    inputs.push({
      inputsFields: name,
      validators: [ required, maxLength(40) ]
    });
  }

  if(userName.length > 0) {
    inputs.push({
      inputsFields: userName,
      validators: [ required, isCapitalize, maxLength(20) ]
    });
  }

  if(phone.length > 0) {
    inputs.push({
      inputsFields: phone,
      validators: [ required, integer, minLength(9), maxLength(13) ]
    });
  }

  if(fileGroupText.length > 0) {
    inputs.push({
      inputsFields: fileGroupText,
      validators: [ maxLength(40) ]
    });
  }
  
  if(requiredFields.length > 0) {
    inputs.push({
      inputsFields: requiredFields,
      validators: [ required ]
    });
  }

  if(requiredSelects.length > 0) {
    inputs.push({
      inputsFields: requiredSelects,
      validators: [ required ]
    });
  }

  inputs.forEach(item => {
    const { inputsFields, validators } = item;

    const handleChange = e => {
      const errors = handleErrors(e.target.value, validators);
      removeErrorInNewFields(e.target);
      if (errors.length > 0) {
        showErrorInNewFields(e.target, errors);
      }
    };
    const handleInput = e => {
      // For file_group_text, we'll handle validation in real-time
      if (e.target.getAttribute('data-validate') !== 'file_group_text') {
        removeErrorInNewFields(e.target);
      } else {
        // Real-time validation for file_group_text
        handleChange(e); 
      }
    };

    Array.from(inputsFields).forEach(input => {
      input.removeEventListener('blur', handleChange);
      input.addEventListener('blur', handleChange);
      input.removeEventListener('input', handleInput);
      input.addEventListener('input', handleInput); 
    });
  })
};

document.addEventListener("turbolinks:load", () => {
  initValidators();
});
