document.addEventListener("turbolinks:load", function() {
    const tariffBox = document.querySelector(".tariff__askR");
    const slider = document.getElementById("switch__slider__askR");
    const yearBtn = tariffBox.querySelector(".switch__year__askR");
    const monthBtn = tariffBox.querySelector(".switch__month__askR");
    let monthly = true;

    function switchToYear() {
        monthly = false;
        slider.style.left = "78px";
        updateTariffValues("yearly");
    }

    function switchToMonth() {
        monthly = true;
        slider.style.left = "4px";
        updateTariffValues("monthly");
    }
    
    function updateTariffValues(period) {
        const priceFigures = tariffBox.querySelectorAll(".price__figure__askR");
        priceFigures.forEach(priceFigure => {
            if (priceFigure.dataset[period]) {
                priceFigure.textContent = priceFigure.dataset[period];
            }
        });

        const priceTexts = tariffBox.querySelectorAll(".price__text__askR");
        priceTexts.forEach(priceText => {
            if (priceText.dataset[period]) {
                priceText.textContent = priceText.dataset[period];
            }
        });

        const features = tariffBox.querySelectorAll(".tariff__feature__askR");
        features.forEach(feature => {
            if (feature.dataset[period]) {
                feature.textContent = feature.dataset[period];
            }
        });
        const buyButtons = tariffBox.querySelectorAll(".tariff__buy__askR");
        console.log(buyButtons);
        buyButtons.forEach(buyButton => {
            if (buyButton.dataset[period]) {
                buyButton.href = buyButton.dataset[period];
            }
        });
    }

    function initializeTariff() {
        const defaultPeriod = monthly ? "monthly" : "yearly";
        updateTariffValues(defaultPeriod);
        slider.style.left = monthly ? "4px" : "78px";
    }

    yearBtn.addEventListener("click", switchToYear);
    monthBtn.addEventListener("click", switchToMonth);

    initializeTariff();
});