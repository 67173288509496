import './mask';

document.addEventListener("turbolinks:load", () => {
  const forms = document.querySelectorAll('form[data-validate="form"]');

  if (forms.length > 0) {
    forms.forEach(form => {
      form.addEventListener('submit', e => {
        const errors = form.querySelectorAll('.form__input.error, .form__select.error, .form__checkbox.error');

        if (errors.length > 0) {
          e.preventDefault();
          $(form).find('input[type=submit], button[type=submit]').addClass('animated shake');
          setTimeout(function () {
            $(form).find('input[type=submit], button[type=submit]').removeClass('animated shake');
          }, 3000);
        }
      })
    });
  }
});
