import IMask from 'imask';
import { url, hideError, showError } from '../validator';

let initializedMasks = [];
const generateTodayDate = () => {
  const today = new Date();
  const delta = 18;

  today.setFullYear(today.getFullYear() - delta)

  return today;
};

export const initMasks = () => {
  const phones = document.querySelectorAll('input[data-phone=true]');
  const integers = document.querySelectorAll('input[data-integer=true]');
  const urls = document.querySelectorAll('input[data-type="url"]');
  const dates = document.querySelectorAll('input[data-type="date"]');
  const birthdayDates = document.querySelectorAll('input[data-type="birthday"]');
  const dateTimes = document.querySelectorAll('input[data-type="datetime"]');
  const passport = document.querySelectorAll('input[data-type="passport"]');
  const passportCode = document.querySelectorAll('input[data-type="passport-code"]');
  const snils = document.querySelectorAll('input[data-type="snils"]');
  const inn = document.querySelectorAll('input[data-type="inn"]');
  const fio = document.querySelectorAll('input[data-type="fio"]');
  const bic = document.querySelectorAll('input[data-type="bic"]');
  const kpp = document.querySelectorAll('input[data-type="kpp"]');
  const correspondentAccount = document.querySelectorAll('input[data-type="correspondent_account"]');
  const maskedInputs = [];

  if (phones.length > 0) {
    phones.forEach(el => maskedInputs.push({
      inputsFields: el,
      options: { mask: '+{0}(000)000-00-00', lazy: false } }));
  }

  if (birthdayDates.length > 0) {
    birthdayDates.forEach(el => maskedInputs.push({
      inputsFields: el,
      options: {
        mask: Date,
        min: new Date(1919, 0, 1),
        max: generateTodayDate(),
        lazy: false
      }
    }));
  }

  if (integers.length > 0) {
    integers.forEach(el => maskedInputs.push({
      inputsFields: el,
      options: { mask: /^\d+$/ }
    }));
  }

  if (dates.length > 0) {
    dates.forEach(el => maskedInputs.push({
      inputsFields: el,
      options: {
        mask: Date,
        min: new Date(1900, 0, 1),
        max: new Date(2030, 0, 1),
        lazy: false
      }
    }));
  }

  if (dateTimes.length > 0) {
    dateTimes.forEach(el => maskedInputs.push({
      inputsFields: el,
      options: {
        mask: 'DD.MM.YY HH:SS',
        lazy: false,
        blocks: {
          DD: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 31
          },

          MM: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 12
          },

          YY: {
            mask: IMask.MaskedRange,
            from: 2019,
            to: 2030
          },

          HH: {
            mask: IMask.MaskedRange,
            from: 0,
            to: 24
          },

          SS: {
            mask: IMask.MaskedRange,
            from: 0,
            to: 59
          },
        }
      }
    }))
  }

  if (passport.length > 0) {
    passport.forEach(el => maskedInputs.push({
      inputsFields: el,
      options: { mask: '00 00 000000' }
    }));
  }

  if (passportCode.length > 0) {
    passportCode.forEach(el => maskedInputs.push({
      inputsFields: el,
      options: { mask: '000-000' }
    }));
  }

  if (snils.length > 0) {
    snils.forEach(el => maskedInputs.push({
      inputsFields: el,
      options: { mask: '000-000-000-00' }
    }));
  }

  if (inn.length > 0) {
    inn.forEach(el => maskedInputs.push({
      inputsFields: el,
      options: { mask: '000000000000' }
    }));
  }

  if (fio.length > 0) {
    fio.forEach(el => maskedInputs.push({
      inputsFields: el,
      options: { mask: /^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯ-]+$/ } }));
  }

  if (bic.length > 0) {
    bic.forEach(el => maskedInputs.push({
      inputsFields: el,
      options: { mask: '000000000' } }));
  }

  if (correspondentAccount.length > 0) {
    correspondentAccount.forEach(el => maskedInputs.push({
      inputsFields: el,
      options: { mask: '00000000000000000000' } }));
  }

  if (kpp.length > 0) {
    kpp.forEach(el => maskedInputs.push({
      inputsFields: el,
      options: { mask: '000000000' } }));
  }

  // init masks

  if (maskedInputs.length > 0) {
    if (initializedMasks.length > 0) {
      initializedMasks.map(mask => mask.destroy());
      initializedMasks = [];
    }

    maskedInputs.forEach(item => {
      const { inputsFields, options } = item;
      const mask = IMask(inputsFields, options);
      initializedMasks.push(mask);
    });
  }

  urls.forEach(el => {
    el.addEventListener('input', e => {
      const val = e.target.value;
      hideError(el);
      url(val) && showError(el ,url(val));
    })
  });
};

document.addEventListener('turbolinks:load', () => {
  initMasks();
});
